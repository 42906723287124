import React from 'react';
import { FaLinkedin, FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import './ContactUs.css'; // Create a CSS file if you want to customize styling

const ContactUs = () => {
  return (
    <div className="contact-container">
      <h2>Contact Us</h2>
      <div className="contact-item">
        <FaLinkedin size={30} color="#0e76a8" />
        <a href="https://www.linkedin.com/company/nextdoor-dukan/" target="_blank" rel="noopener noreferrer">
        https://www.linkedin.com/company/nextdoor-dukan/
        </a>
      </div>
      <div className="contact-item">
        <FaPhoneAlt size={30} color="#4CAF50" />
        <span>+91 8079069160</span>
      </div>
      <div className="contact-item">
        <FaEnvelope size={30} color="#D44638" />
        <a href="mailto:support@nextdoordukan.com">support@nextdoordukan.com</a>
      </div>
      <div className="contact-item">
        <FaMapMarkerAlt size={30} color="#F44336" />
        <span>Ground Floor, D-9, Sector 3, Gautam Buddha Nagar, Uttar Pradesh, 201301</span>
      </div>
    </div>
  );
};

export default ContactUs;
