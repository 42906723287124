import React, { useState } from 'react';
import './App.css';
import axios from 'axios';
import ContactUs from './ContactUs';
import { translations, LanguageSwitcher } from './LanguageSwitcher';

function App() {
  const [selectedContent, setSelectedContent] = useState('Home');
  const [language, setLanguage] = useState('English');
  const [pincode, setPincode] = useState('');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleButtonClick = (content) => {
    setSelectedContent(content);
    setIsMobileMenuOpen(false);
  };

  const handlePincodeSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`https://pincode-nextdoordukan.azurewebsites.net/api/pincode`, { pincode });
      alert(`Coming soon to your Pincode: ${pincode}`);
      console.log(response.data);
    } catch (err) {
      console.error('Error saving pincode:', err);
    }
  };

  const t = translations[language];

  return (
    <div className="app-container">
      {/* Mobile Header */}
      <div className="mobile-header">
        <button 
          className="menu-toggle"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          ☰
        </button>
        <h1>{t.title}</h1>
        <div className="mobile-language-switcher">
          <LanguageSwitcher 
            currentLanguage={language} 
            onLanguageChange={setLanguage}
          />
        </div>
      </div>

      {/* Main Content */}
      <div className="main-layout">
        {/* Sidebar */}
        <div className={`sidebar ${isMobileMenuOpen ? 'open' : ''}`}>
          <button 
            className={`nav-button ${selectedContent === 'Home' ? 'active' : ''}`}
            onClick={() => handleButtonClick('Home')}
          >
            {t.home}
          </button>
          <button 
            className={`nav-button ${selectedContent === 'Contact' ? 'active' : ''}`}
            onClick={() => handleButtonClick('Contact')}
          >
            {t.contact}
          </button>
          <button 
            className={`nav-button ${selectedContent === 'Order' ? 'active' : ''}`}
            onClick={() => handleButtonClick('Order')}
          >
            {t.order}
          </button>
        </div>

        {/* Content Area */}
        <div className="content">
          <header className="desktop-header">
            <h1 className='site-title'>{t.title}</h1>
            <div className="language-switcher-container">
              <LanguageSwitcher 
                currentLanguage={language} 
                onLanguageChange={setLanguage}
              />
            </div>
          </header>

          <main className="main-content">
            {selectedContent === "Home" && <p>{t.description}</p>}
            {selectedContent === "Contact" && <ContactUs language={language} />}
            {selectedContent === "Order" && (
              <div className="order-form">
                <p>{t.enterPincode}</p>
                <form onSubmit={handlePincodeSubmit}>
                  <input
                    type="text"
                    placeholder={t.pincodePrompt}
                    value={pincode}
                    onChange={(e) => setPincode(e.target.value)}
                    className="pincode-input"
                  />
                  <button type="submit" className="submit-button">
                    {t.submit}
                  </button>
                </form>
              </div>
            )}
          </main>
        </div>
      </div>
    </div>
  );
}

export default App;